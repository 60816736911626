<template>
	<!-- <keep-alive>
		<router-view/>
	</keep-alive> -->
	<router-view v-if="$route.meta.keepAlive" v-slot="{ Component }">
		<keep-alive >
			<component :is="Component" ></component>
		</keep-alive>
	</router-view>

	<router-view v-if="!$route.meta.keepAlive">
	</router-view>

	<loading v-if="loading"></loading>
</template>
<script >
import { useStore } from 'vuex'
import Loading from '@/components/loading.vue'
import { defineComponent, computed, } from 'vue';
export default defineComponent({
	name: 'App',
	components: {
		Loading,
	},
	setup() {
		const store = useStore()
		return {
			loading: computed(() => store.state.loading)
		}
	}
})

</script>
<style> /* @import "./assets/colorUI.css";  */
 html,
 body {
 	height: 100%;
 	max-width: 860px;
 	margin: 0 auto;
 }

 #app {
 	height: 100%;
 	max-width: 860px;
 }

 .page-component-wrap {
 	height: 100%;
 	padding-bottom: 52px;
 	box-sizing: border-box;
 }

 .btn-readonly {
 	pointer-events: none;
 	-webkit-pointer-events: none;
 }

 .van-nav-bar,
 .form_btn,
 .van-search,
 .search_question,
 .van-tabbar,
 .van-tabs {
 	max-width: 860px;
 	left: auto !important;
 }</style>
