import store from '../store'
import axios from './index.js'

// 判断公众号截取code
const getUrlParam = (name) => {
	
	let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	let r = window.location.href.substring(1).match(reg);
	
	if (r != null) {
		return decodeURI(r[2]);
	}
	
	return null;
}

// 获取appId
async function getAppId(data,module,auth) {

	if(module==""){   //如果没有限制模板库
		return true
	}
	// 空格转换成加号
	// let companyid = data.replace(/[\s]+/g,'+')
	let companyid = data
	// 判断是否传入新的companyid
	//store.commit("openid", "oYsgc6ynutzcNG3LqSOB0642p0g0")
	if(companyid != store.state.companyid || !store.state.companyid){
		store.commit("companyid", companyid)
		store.state.openid = ''
	}

	let appid = ''          //公众号 appid
	let isappid = ''          //是否配置微信
	let serviceTypes=true    //开通权限
	

	await axios.post("/WxApi/Wx/GetAppId", {
			data:'',
			timeStamp:'',
			token:companyid,
			sign:'',
	     })
		.then(res => {
			
			if (res.status) {
				let moduleData=res.data.serviceTypes||""
				let data=moduleData.split(",")

				if(data.includes(module)){
					serviceTypes=true
				}else{
					serviceTypes=false
				}
				
				
				// if(data.length>0){
				// 	serviceTypes=true
				// }else{
				// 	serviceTypes=false
					
				// }
				
				// if(type.indexOf(module) ==-1){
				   
				// 	serviceTypes="false"
				// }else{
				// 	console.log(121212)
				// 	serviceTypes="false"
				// }
				appid = res.data.appID	
			
				store.commit("appid", res.data.appID)
			}else{
				
				serviceTypes=false
			}
		}).catch(err => { console.log(err)});

	//  如果没开通权限  则返回 false
	console.log(serviceTypes)
	if(!serviceTypes){
	
		return false
	}
	
	//判断是否需要授权
	if(auth==false){
		
		return true
	}
    // //判断是否开通配置公众号
	// if(isappid=="false"){   
	// 	return isappid
	// }


	//判断是否存在openid
	if (store.state.openid!=undefined && store.state.openid!='undefined' && store.state.openid!='' && store.state.openid) {
		
		return true
	}

	let code='' 
	let local = window.location.href;
	let isopenid=''        //公众号 openid
	
	if (getUrlParam("code")) {  // 获取openId
		code = getUrlParam("code")
		let pars={
			  Code: code
		   }
		await axios.post("/WxApi/Wx/GetOpenId", {
				data:JSON.stringify(pars),
				timeStamp:'',
				token:companyid,
				sign:'',
	       })
			.then(res => {
				if (res.status == true) {
					console.log(121)
					store.commit("openid", res.data)
					isopenid="true"   
				}
			}).catch(err => {});
		if(isopenid=="true"){ 	
			return true 
		}
		
	} else {
		//不存在就打开上面的地址进行授权
		 
		//window.location.href =
		//`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
		return true
	}
	
}

export {
	getAppId
}
