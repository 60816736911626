import axios from 'axios'
import { createApp } from 'vue'
import store from '../store'

const qs = require('qs');
let $this = createApp() ;  

// var BASRURL = 'https://wxapi.tms568.com';
let BASRURL = window.g.ApiUrl  
if(process.env.NODE_ENV == "production"){
	BASRURL = 'https://wxapi.tms568.com'
}

var loading;
axios.interceptors.request.use(config => { 
	
	config.headers['X-Requested-With'] = 'XMLHttpRequest';
	axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
	store.commit('showLoading')
	return config
}, error => { 
	store.commit('hideLoading')
	return Promise.reject(error)
})

axios.interceptors.response.use(response => { 
	store.commit('hideLoading')
	return response
}, error => { 
	store.commit('hideLoading')
	return Promise.resolve(error.response)
}) 
// const checkStatus = response => { 
// 	const config = response.config
// 	if(!config || !config.hideloading){
// 		//$this.loading.$bus.emit('loading', {state:false})
// 	}  
// 	if (response && (response.status === 200)) {
// 		return response.data
// 	} else if (response && (response.status === 400)) {
// 		$this.$toast(response.data.msg)
// 		return
// 	} else if (response && (response.status === 500)) {
// 		$this.$toast('服务器忙请稍后再试')
// 		return
// 	}
// }

//url:url 地址
//params:请求参数

export default {
	
	post(url, data, newurl, config,trans = false) {  
		
		return new Promise((resolve, reject) =>{   
		 axios({
			method: 'POST',
			url: BASRURL  + url,
			data: data,
			timeout: 30000,
			headers: {
				'X-Requested-With': 'XMLHttpRequest', 
				'Content-Type': 'application/json',
				// 'Content-Type': 'multipart/form-data'
			}
		}).then(
			(response) => {
				
				resolve(response.data)
			}
		).catch(err => {
			reject(err.data)
		})
	})
	},

	upload(url, data, newurl, config,trans = false) {  
		
		return new Promise((resolve, reject) =>{   
		 axios({
			method: 'POST',
			url: BASRURL  + url,
			data: data,
			timeout: 30000,
			headers: {
				'X-Requested-With': 'XMLHttpRequest', 
				// 'Content-Type': 'application/json',
				'Content-Type': 'multipart/form-data'
			}
		}).then(
			(response) => {
				
				resolve(response.data)
			}
		).catch(err => {
			reject(err.data)
		})
	})
	},
	
	get(url, params, noModal) {  
		 axios({
			method: 'get',
			url:  url,
			params, // get 请求时带的参数
			timeout: 30000,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		}).then( 
			(response) => { 
				resolve(response.data)
			}
		).catch(err => {
			reject(err.data)
		})
	}
}
