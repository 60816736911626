import { createApp } from 'vue'
import mitt from 'mitt'
import App from './App.vue'
import router from './router'
import { PullRefresh } from 'vant';
import { Button } from 'vant';
import { Field, CellGroup ,Cell} from 'vant';
import { Tab, Tabs } from 'vant';
import { Tag } from 'vant';
import { Search } from 'vant';
import { List } from 'vant';
import { Step, Steps } from 'vant';
import { Uploader } from 'vant';
import { Popup } from 'vant';
import { Picker,DatetimePicker } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Overlay } from 'vant';
import { Icon } from 'vant';
import { Dialog } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Col, Row } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { TreeSelect ,DropdownMenu,DropdownItem } from 'vant';
import { NavBar } from 'vant';
import { ImagePreview } from 'vant';
import store from './store/index.js'
import axios from './axios/index.js'
import date from './axios/datetime.js'
import { Loading } from 'vant';
import { Sticky } from 'vant'; 
import vueEsign from 'vue-esign'
const app = createApp(App);



app.config.globalProperties.$bus = new mitt(); 
app.config.globalProperties.$http = axios; 

app.config.globalProperties.$date = date; 


app.use(store);
app.use(Button);
app.use(Field);
app.use(Cell);
app.use(CellGroup);
app.use(Search);
app.use(Tag);
app.use(PullRefresh);
app.use(Uploader);
app.use(Popup);
app.use(DatetimePicker);
app.use(Picker);
app.use(Tab);
app.use(Tabs);
app.use(List);
app.use(Step);
app.use(Steps);
app.use(Swipe);
app.use(SwipeItem);
app.use(Overlay);
app.use(Icon);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(Radio);
app.use(RadioGroup);
app.use(TreeSelect);
app.use(NavBar);
app.use(Col);
app.use(Row);
app.use(Dialog);
app.use(Loading);
app.use(Sticky);
app.use(ImagePreview);
app.use(vueEsign);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(router).mount('#app')