<template>
	<!-- loading -->
	<!-- <div class="cu-load load-modal" >
		<img src='../assets/logo.png' class='png' />
		<div class='gray-text' style="line-height: 25px;">加载中...</div>
	</div> -->
    <!-- <div class="load-modal">
        
    </div> -->
    <!-- <van-overlay :show="show=true" >
        <div class="load-modal">
            <van-loading size="24px" vertical color="#1989fa">加载中...</van-loading>
        </div>
</van-overlay> -->
<div class="load-modal">
    <van-loading size="24px" text-size="16px" vertical color="#1989fa">加载中...</van-loading>
</div>
</template>

<script >
	 import {defineComponent, toRefs, computed, ref, reactive, onMounted} from 'vue';
	 export default defineComponent({
        name: "",
        components: {},
        setup() {
            const reactiveData = reactive({});
 
            onMounted(() => {
 
            });
 
            return {
                ...toRefs(reactiveData),
            };
        },
    });
</script>

<style>
.load-modal{
    position: fixed;
	top: 0;
	right: 0;
	bottom: 70px;
	left: 0;
	margin: auto; 
	width: 120px;
	height: 120px;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 5px;
	/* box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.5); */
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	z-index: 9999;
	line-height: 2.4rem;
}
.van-overlay{
    z-index: 99999;
	
}
</style>
