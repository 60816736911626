<template>
	<div class="home">
		<!-- 轮播图 -->
		<div class="swiperWrap">
			<van-swipe :autoplay="3000" lazy-render>
				<van-swipe-item>
					<img src="../../assets/images/pic_banner.png" alt="" />
				</van-swipe-item>
				<van-swipe-item>
					<img src="../../assets/images/pic_banner.png" alt="" />
				</van-swipe-item>
			</van-swipe>

		</div>
		<!-- 菜单 -->
		<div class="homemenus">
			<div class="homemenu" v-for="(item, index) in menus" :key="item.text" @click="menuEvent(item.path)">
				<img :src="item.img" class="menuimg" />
				<div class="menutext">{{ item.text }}</div>
			</div>
		</div>
	</div>
</template>

<script setup>


import { Toast } from 'vant'
import { getCurrentInstance } from 'vue'
const _this = getCurrentInstance().appContext.config.globalProperties


//  菜单
let menus = [
	{
		img: require('../../assets/images/icon-shorder.png'),
		text: '快速下单',
		path: "/Home"
	},
	{
		img: require('../../assets/images/icon-xiadanjilu.png'),
		text: '下单记录',
		path: "/Home"
	},
	{
		img: require('../../assets/images/icon-yundanchaxun.png'),
		text: '运单查询',
		path: "/UnitCheck"
	},
	{
		img: require('../../assets/images/icon-yundanchaxun.png'),
		text: '我的运单',
		path: "/UnitList"
	},
	{
		img: require('../../assets/images/icon-bangding.png'),
		text: '手机绑定',
		path: "/BangDing"
	},
	{
		img: require('../../assets/images/icon-bangding.png'),
		text: '快速签收',
		path: "FastSingln"
	}
]

const menuEvent = (path) => {

	if (path) {

		console.log(path)
		_this.$router.push({
			path: path,
			query: {
				companyid: _this.$store.state.companyid,
			}
		});
	} else {
		Toast('该功能未开放');

	}
}

</script>

<style scoped>
.swiper {
	width: 100%;

}

.swiperWrap {
	padding: 15px;
	box-sizing: border-box;
}

.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	line-height: 150px;
	text-align: center;
	background-color: #39a9ed;
}

.van-swipe-item img {
	width: 100%;
	border-radius: 10px;
}

.homemenus {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.homemenu {
	text-align: center;
	width: 33.3333%;
	margin: 10px 0px 15px 0px;
}

.menuimg {
	width: 36px;
	height: 36px;
}

.menutext {
	font-size: 14px;
	color: #333333;
	height: 15px;
	line-height: 15px;
	margin-top: 8px;
}</style>