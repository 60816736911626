
import {createStore} from 'vuex'
import VuexAlong from 'vuex-along'


const store = createStore({
	state: {
		openid: sessionStorage['openid'] ? sessionStorage['openid'] : '', //当前 openid
		companyid: sessionStorage['companyid'] ? sessionStorage['companyid'] : 0, //当前companyid
		appid: sessionStorage['appid'] ? sessionStorage['appid'] : "", //当前appid
	},
	// 更改store的状态 同步 this.$store.commit("increment")
	mutations: {
		companyid(state, companyid) { 
			state.companyid = companyid;
			sessionStorage['companyid'] = companyid;
		},
		openid(state, openid) {
			state.openid = openid
			sessionStorage['openid'] = openid;
		},
		appid(state, appid) {
			state.appid = appid
			sessionStorage['appid'] = appid;
		},
    // 加载框
     showLoading(state){
      state.loading = true    
      },
      hideLoading (state) {
        state.loading = false
      }
   
	},
	// 有异步的时候， 需要action this.$store.dispatch("decrement")
	actions: {
		// ChangeBtnreadonly(state,val){
		// 	 state.commit('ChangeBtnreadonly',val)
		// },
	},
	plugins: [
		VuexAlong({
			name: "vuexalong",
		})
	]

})
export default store;
