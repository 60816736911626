
// 截取日月
function dateDay(date) {
	if (date) {
		return date.substring(5, 10)
	}
}
// 截取时分
function dateHour(date) {
	if (date) {
		return date.substring(11, 16)
	}
}
// 截取年月日
function dateYMDay(date) {
	if (date) {
		return date.substring(0, 10)
	}
}

// 截取年月日时分
function dateTime(date) {
	if (date) {
		return date.substring(0, 16)
	}
}

// 时间转换
function dateFormat(val, type){
	let time = ''
	  if (val !== null) {
		const date = new Date(val)
		const year = date.getFullYear()
		var month = date.getMonth() + 1
		month = month > 9 ? month : '0' + month
		var day = date.getDate()
		day = day > 9 ? day : '0' + day
		var hh = date.getHours()
		hh = hh > 9 ? hh : '0' + hh
		var mm = date.getMinutes()
		mm = mm > 9 ? mm : '0' + mm
		var ss = date.getSeconds()
		ss = ss > 9 ? ss : '0' + ss
		var ms = date.getMilliseconds()
		if (type === 'fullTime') {
		  time = year + '年' + month + '月' + day + '日 ' + hh + '点' + mm + '分'
		} else if (type === 'time') {
		  time = hh + '点' + mm + '分' + ss + '秒'
		} else if (type === 'day') {
		  time = year + '-' + month + '-' + day
		} else if (type === 'langFullTime') {
		  time = year + '年' + month + '月' + day + '日 ' + hh + '点' + mm + '分' + ss + '秒' + ms + '毫秒'
		} else if (type === 'dataTime') {
			time = year + '-' + month + '-' + day+" "+hh+':'+mm
		  }
	  }
	  return time
}

//获取当前年月日 时分秒
function formatTime(fmt) { // 
	var  that = new Date();
	var o = {
	  "M+": that.getMonth() + 1, // 月份
	  "d+": that.getDate(), // 日
	  "h+": that.getHours(), // 小时
	  "m+": that.getMinutes(), // 分
	  "s+": that.getSeconds(), // 秒
	  "q+": Math.floor((that.getMonth() + 3) / 3), // 季度
	  "S": that.getMilliseconds() // 毫秒
	};
	if (/(y+)/.test(fmt))
	  fmt = fmt.replace(RegExp.$1, (that.getFullYear() + "").substr(4 - RegExp.$1.length));
	for (var k in o)
	  if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return fmt;
  }
  //获取后面几天的时间
  function getDateStr(AddDayCount) {
	var dd = new Date();
	dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期  
	var y = dd.getFullYear();
	var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1);//获取当前月份的日期，不足10补0  
	var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();//获取当前几号，不足10补0  
   
	return  m + "-" + d;
  }; 
  //获取后面年月日天的时间
  function getDateyStr(AddDayCount) {
	var dd = new Date();
	dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期  
	var y = dd.getFullYear();
	var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1);//获取当前月份的日期，不足10补0  
	var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();//获取当前几号，不足10补0  
	return  y + "-" + m + "-" + d;
  }; 


export default {
	dateDay,
	dateHour,
	dateYMDay,
	dateTime,
	dateFormat,
	formatTime,
	getDateStr,
	getDateyStr,
}
