<template>
	<div class="container">
		<div class="title">您没有权限访问该链接!</div>
	</div>
</template>

<script>

</script>

<style scoped>
	.container{
		width: 100%;
		height: 100vh;
		position: relative;
	}
	.title{
		color: red;
		font-size: 18px;
		text-align: center;
		padding-top: 70px;
	}

</style>
